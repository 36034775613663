import { Configuration, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import "@popperjs/core";
// Sentry
import * as Sentry from "@sentry/react";
import { CONSOLE_LEVELS } from "@sentry/utils";
// Bootstrap
import "bootstrap";
import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
import { RootMenuProvider } from "components/menu/RootMenuProvider";
import React, { Suspense } from "react";
import { BrowserRouter, HashRouter, Redirect, Route, Switch, useHistory } from "react-router-dom";
import settings from "settings";
import "./assets/style/custom.scss";
const NestorMain = React.lazy(() => import("applications/nestor/pages/Main"));
const XemwayGlobalUI = React.lazy(() => import("applications/xemway/pages/Main"));

Sentry.init({
  dsn: `https://${settings.sentry.public}@${settings.sentry.url}/${settings.sentry.project}`,
  integrations: [], //[new Integrations.BrowserTracing()],
  release: `xemway@${process.env.REACT_APP_NPM_VERSION}`,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
const Router: React.FunctionComponent<{}> = () => {
  // Automatically loads nestor.xemway.ch to the nestor app
  let hostname = document.location.hostname.split(".")[0];
  let defaultApp = "xemway";
  if (hostname === "nestor") {
    defaultApp = "nestor";
  }

  const hashPos = document.location.href.lastIndexOf("#");
  const hash = document.location.href.substring(hashPos + 1);
  const history = useHistory();

  console.log(document.location.href);
  console.log(hash);
  console.log(process.env.REACT_APP_URL);

  if (hash.startsWith("/nestor")) {
    document.location.href = process.env.REACT_APP_URL + hash;
    return null;
  }

  return (
    <BrowserRouter>
      <RootMenuProvider>
        <Switch>
          <Route path="/xemway">
            <Suspense fallback={<div className="alert alert-info">Loading xemway</div>}>
              <XemwayGlobalUI />
            </Suspense>
          </Route>
          <Route path="/nestor">
            <Suspense fallback={<div className="alert alert-info">Loading nestor</div>}>
              <NestorMain />
            </Suspense>
            {/*
            <div className="alert alert-danger">
              <strong>WARNING !</strong> We noticed a corruption in the database. We're currently restoring a database
              backup. Please come back later
            </div>

  */}
          </Route>

          <Route>
            <Redirect to={`/${defaultApp}`} />
          </Route>
        </Switch>
      </RootMenuProvider>
    </BrowserRouter>
  );
};

const App = Router; //WithLoginEnvironment(Router);

export default App;

const v = {
  BACKEND_URL: "https://api.xemway.ch",
  AUTH_URL: "https://auth.xemway.ch",
  NESTOR_URL: process.env.REACT_APP_NESTOR_BACKEND_URL as string,
  AVATAR_URL: "https://avatar.bue.csem.local/",
  influxDB: {
    host: "https://xemway.ch:8086",
  },

  sentry: {
    public: "dca5c670f38e46649621bb6da2551409",
    url: "o1066188.ingest.sentry.io",
    project: "6058748",
  },

  mqtt_topics: {
    system: "@XEM",
    ble_pairings: "ble_pairing",
    BLEStatus: {
      paired: {
        topic: "$BLE/System/Paired",
        onValue: true,
        offValue: false,
      },
      connected: {
        onValue: true,
        offValue: false,
      },
      connecting: {
        onValue: true,
        offValue: false,
      },
      autoconnect: {
        topic: "$BLE/System/Autoconnect",
        onValue: 1,
        offValue: 0,
      },
    },
  },

  connectionChannels: {
    USB: "usb",
    BLE: "ble",
  },

  terminal: {
    api_endpoint: "/endpoint",
  },
};

export default v;

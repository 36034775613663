import assert from "assert";
import _ from "lodash";
import { v4 } from "uuid";
import React, { useCallback, useContext, useEffect, useMemo, useReducer } from "react";
export type MenuTrunk = MenuLeaf & {
  children: Array<MenuTrunk>;
  parent?: MenuTrunk;
  uid: string;
};

type MenuLeaf = {
  path?: string;
  label?: string;
  icon?: string;
  render?: boolean;
  selected?: boolean;
  hasNumber?: number;
};

export enum menuActionType {
  ADD_SUBMENU,
  REMOVE_SUBMENU,
  MODIFY_MENU,
  MODIFY_SELF,
}

export type menuAction = {
  type: menuActionType;
  modifications?: Partial<MenuTrunk>;
  uid?: string;
};

const menuReducer = (state: MenuTrunk, action: menuAction): MenuTrunk => {
  if (action.type === menuActionType.ADD_SUBMENU) {
    /*  console.log(
      "[REDUCER - ADDSUBMENU] - Adding submenu " + action.modifications?.uid + " in menu " + state.uid + " With ",
      action.modifications
    );*/
    assert(action.modifications);
    return {
      ...state,
      children: [...state.children, { ...{ children: [], label: "", path: "", uid: "" }, ...action.modifications }],
    };
  }

  if (action.type === menuActionType.REMOVE_SUBMENU) {
    assert(action.uid);
    const menuToRemove = state.children.find((e) => e.uid === action.uid);
    assert(menuToRemove);

    // console.log("[REDUCER - REMOVE] - Removing submenu " + action.uid + " in menu " + state.uid);
    // console.log(_.without(state.children, menuToRemove));
    return { ...state, children: _.without(state.children, menuToRemove) };
  }

  if (action.type === menuActionType.MODIFY_MENU) {
    assert(action.modifications);
    assert(action.uid);
    /* console.log(
      "[REDUCER] - Changing submenu " + action.uid + " in menu " + state.uid + " With ",
      action.modifications
    );
*/
    const menuToChange = state.children.find((e) => e.uid === action.uid);

    if (menuToChange) {
      const index = state.children.indexOf(menuToChange);
      assert(index > -1);
      //Object.assign(menuToChange, action.modifications);

      return {
        ...state,
        children: [
          ...state.children.slice(0, index),
          { ...menuToChange, ...action.modifications },
          ...state.children.slice(index + 1),
        ],
      };
    } else {
      //   console.error("No menu to change");
      return state;
    }
  }

  if (action.type === menuActionType.MODIFY_SELF) {
    assert(action.modifications);
    //  console.log("Modiyfing self with ", action.modifications);
    return { ...state, ...action.modifications };
  }

  return state;
};

/**
 * A XemwayMenuContext provider for a menu item.
 * Used to wrap a component with the provider. Sub-menus from that component can be created by using useMenuElement();
 * @param props Component props
 * @param props.menu A menu element
 * @param props.setMenu A menu setter
 * @returns
 */

export const XemwayMenuContext = React.createContext<
  | { menu: MenuTrunk; reduce: React.Dispatch<menuAction>; setMenu: (el: Partial<MenuTrunk>) => void; uid: string }
  | undefined
>(undefined);

export const XemwayMenuProvider = (props: React.PropsWithChildren<{ menu?: MenuTrunk; readonly uid: string }>) => {
  const topMenuCtx = useContext(XemwayMenuContext);
  const topMenuRed = topMenuCtx?.reduce;
  const { uid } = props;
  const [reducedMenu, reduce] = useReducer(menuReducer, { children: [], uid });

  useEffect(() => {
    return () => {
      if (!topMenuRed) {
        return;
      }

      topMenuRed({
        type: menuActionType.MODIFY_MENU,
        uid: uid,
        modifications: { children: [] },
      });
    };
  }, [topMenuRed, uid]);

  //console.log("[PROVIDER] ", uid, reducedMenu.children);
  useEffect(() => {
    //console.log("Attemtping to modify my own menu" + reducedMenu.path);
    if (topMenuRed && uid) {
      topMenuRed({
        type: menuActionType.MODIFY_MENU,
        uid: uid,
        modifications: reducedMenu,
      });
    }
  }, [topMenuRed, reducedMenu, uid]);

  const setMenu = useCallback((el: Partial<MenuTrunk>) => {
    reduce({
      type: menuActionType.MODIFY_SELF,
      modifications: el,
    });
  }, []);

  return (
    <XemwayMenuContext.Provider
      value={{
        menu: reducedMenu,
        reduce,
        setMenu,
        uid,
      }}
    >
      {props.children}
    </XemwayMenuContext.Provider>
  );
};

export function useMenuElement(
  label: string,
  path: string,
  render?: boolean,
  icon?: string
): {
  menu?: MenuTrunk;
  setMenu: (el: MenuTrunk) => void;
  uid: string;
} {
  const supMenuCtx = useContext(XemwayMenuContext);
  const dispatch = supMenuCtx?.reduce;

  const uid = useMemo(() => {
    return v4();
  }, []);

  useEffect(() => {
    assert(uid);
    if (dispatch) {
      //console.log("adding menu ", uid, "in parent menu " + supMenuCtx.uid);
      dispatch({ type: menuActionType.ADD_SUBMENU, modifications: { uid, children: [] } });
      return () => {
        //  console.log("REMOVING SUBMENUS");
        dispatch({ type: menuActionType.REMOVE_SUBMENU, uid: uid });
      };
    }
  }, [dispatch, uid]);

  const updateSelf = useCallback(
    (el: Partial<MenuTrunk>) => {
      if (dispatch) {
        if (!uid) {
          return;
        }
        //   console.log("ok to dispatch new menu ", el.label, uid, "in parent menu " + supMenuCtx.uid);
        dispatch({ type: menuActionType.MODIFY_MENU, uid: uid, modifications: el });
      }
    },
    [uid, dispatch]
  );

  useEffect(() => {
    if (!uid) {
      return;
    }

    updateSelf({ label, path, render, icon });
  }, [uid, label, path, render, icon, updateSelf]);

  // const myMenu = supMenu?.children.find((e) => e.uid === uid);
  return { setMenu: updateSelf, uid: uid };
}
